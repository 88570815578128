export default {
  "appFooterMessageOr": "or",
  "appFooterMessagePost": ". Thanks.",
  "appFooterMessagePre": "For queries, please send message to AFD hotline",

  "appHeaderTitleMain": "Jockey Club Food Assistance Programme",

  "appHeadertitleSub": "Food Angel Automated Food Dispenser Service",

  "componentOctopusInputNoCheckDigitCheckedLabel": "If the Octopus Card number does not have a number in brackets, please check this box",

  "componentUploadFileButtonLabel": "Select Image",
  "componentUploadFileOpenFileButtonLabel": "Open file in a new window",
  "componentUploadFilePreviewTitle": "Preview file",
  "componentUploadFileRemoveConfirmMessage": "Confirm to delete this image?",

  "errorMessageRequiredInputAny": "Please enter %{name}",
  "errorMessageRequiredSelectAny": "Please select %{name}",
  "errorMessageRequiredUploadAny": "Please upload %{name}",
  "errorMessageUnknownError": "Unknown errors. Please try again later",

  "page1ScreenButtonSubmitLabel": "Confirm",
  "page1ScreenErrorMessageInvalidToken": "Incorrect verification code. Please check again your SMS",
  "page1ScreenErrorMessageInvalidUser": "Sorry. Unable to find this application record. If you have any questions, please contact us",
  "page1ScreenFormAlertContentPost": ". Do not share this URL to others!",
  "page1ScreenFormAlertContentPre": "This application form only belongs to the applicant",
  "page1ScreenFormAlertTitle": "Reminder",
  "page1ScreenFormTokenLabel": "4-digit verification code",
  "page1ScreenFormTokenLengthErrorMessage": "Please enter the 4-digit number verification code",
  "page1ScreenHeaderSub": "AFD Application",
  "page1ScreenHeaderSubRenewal": "AFD Application for service extension",
  "page1ScreenHeaderSubRight": "Applicant: %{name}",
  "page1ScreenSuccessMessage": "Verification code is correct",
  "page1ScreenTitle": "Please enter the verification code in the SMS to continue the application",

  "page2ScreenButtonSubmitLabel": "Next Step",
  "page2ScreenFormStatusErrorMessage": "Please indicate your application intention",
  "page2ScreenFormStatusLabel": "Please indicate your intention:",
  "page2ScreenFormStatusOptionConfirm": "Proceed",
  "page2ScreenFormStatusOptionReject": "Reject",
  "page2ScreenFormStatusOptionSelectEFoodBank": "E-Food Bank",
  "page2ScreenFormStatusOptionSelectHotMeal": "Hot Meal",
  "page2ScreenIntroduction1": "Food Angel's Automated Food Dispenser (AFD) service is one of the long term food assistance service in the Jockey Club Food Assistance Programme. Successful applicants will be given membership with a QR code or registered Octopus card, and will be able to collect free cook-chill meals from all AFDs in different districts for up to one year. The meals can be reheat at home at flexible hours.",
  "page2ScreenIntroduction2": "For AFD locations, please visit Food Angel website: ",
  "page2ScreenIntroduction3": "Please submit the required documents within 5 days following the instructions below, or the programme will automatically cancel the application, and applicants will need to reapply through the website.",
  "page2ScreenIntroduction4": "As there are many applications received, applicants may need to wait for 3 to 6 months to officially start the AFD service. The actual waiting time depends on the application in each district.",
  "page2ScreenIntroductionOption1": "I confirm my application for AFD service, and will upload the required documents within 5 days to complete application. I understand that I may need to wait 3 to 6 months to start the AFD service after approval.",
  "page2ScreenIntroductionOption2": "I withdraw my application for AFD service, and understand the application process will end, and I will receive an automatic message from Hong Kong Jockey Club.",
  "page2ScreenIntroductionOption3": "If you wish to be transferred to the \"Hot Meal\" service, and your case will be referred to Food For Good.",
  "page2ScreenIntroductionOption4": "I withdraw my application for AFD service, and wish to apply E-food bank instead. Food Angel team will reject my application for AFD within 5 working days, as the data I have input do not match criteria for short term food assistance services. I will reapply for E-food bank service at the Jockey Club Food Assistance Programme website.",
  "page2ScreenIntroductionRenewal1": "Please submit the required documents within 5 days, or the service end date will not be changed (52 weeks from the service start date).",
  "page2ScreenIntroductionRenewal2": "Please note that there is a quota for extension, the Food Angel social service team will approve the case according to the family income. As vetting procedure takes time, please wait patiently, and eligible members will be informed through SMS later.",
  "page2ScreenIntroductionRenewalOption1": "I acknowledge my service end date, and I do not need further food assistance, and will not apply for service extension.",
  "page2ScreenIntroductionRenewalOption2": "I am still in need of food assistance, and I will apply for AFD service extension, and will submit documents accordingly.",
  "page2ScreenStatusModalConfirmButtonCancelLabel": "Back",
  "page2ScreenStatusModalConfirmButtonConfirmLabel": "Confirm to proceed",
  "page2ScreenStatusModalConfirmContentReject": "You will receive a SMS from HKJC regarding the cancellation.",
  "page2ScreenStatusModalConfirmContentSelectEfoodBank": "As the information input in the application page does not meet the requirement of this short term service, our staff will cancel your application for AFD, and you are required to resubmit your application through the JC website.",
  "page2ScreenStatusModalConfirmContentSelectHotMeal": "Your case will be referred to Food For Good.",
  "page2ScreenStatusModalConfirmTitleReject": "You have chosen to cancel the application of AFD service",
  "page2ScreenStatusModalConfirmTitleSelectEfoodBank": "You have chosen to apply for \"E-Food Bank\" instead",
  "page2ScreenStatusModalConfirmTitleSelectHotMeal": "You have chosen to be transferred to the hot meal service",
  "page2ScreenStatusRenewalModalConfirmContentReject": "I confirm the service end date, and will not apply for service extension.",
  "page2ScreenStatusRenewalModalConfirmTitleReject": "You have chosen to abort the application of extension service",
  "page2ScreenSubmitModalButtonCancelLabel": "Back",
  "page2ScreenSubmitModalButtonConfirmLabel": "Confirm Submission",
  "page2ScreenSubmitModalContent": "Are you sure to submit the application information? You cannot modify it after submitting",
  "page2ScreenSubmitModalTitle": "Submit Application Information",
  "page2ScreenTitle": "Service Introduction and Document Submission",

  "page3ScreenAutosavingMessageSaveButtonLabel": "Save",
  "page3ScreenAutosavingMessageSaved": "Form has been saved",
  "page3ScreenAutosavingMessageSaving": "Auto-saving...",
  "page3ScreenAutosavingMessageUnsaved": "Some information has not been saved yet",
  "page3ScreenButtonSubmitLabel": "Submit",
  "page3ScreenErrorMessageInvalidToken": "Operation timeout. Please login again",
  "page3ScreenFoodSafetyCheckboxButtonErrorMessage": "Please open and read through the agreement before checking this box",
  "page3ScreenFoodSafetyCheckboxButtonLabel": "Click to open the Food Safety Agreement",
  "page3ScreenFoodSafetyCheckboxText": "I have read through and agree to the Food Safety Agreement",
  "page3ScreenFormAddressProofImage": "Address proof document",
  "page3ScreenFormFoodSafetyErrorMessage": "Please agree to the Food Safety Agreement",
  "page3ScreenFormFoodSafetyLabel": "Food safety agreement",
  "page3ScreenFormIncomeProofAmountErrorMessageInvalid": "Please enter the correct amount",
  "page3ScreenFormIncomeProofAmountLabel": "Family total income amount",
  "page3ScreenFormIncomeProofClaimContentPost": "hereby declare that the information provided above is true. I undesrtand and agree that if the information provided is false to defraud food assistance service, the membership of my family will be revoked, and the organization reserves all rights to investigation.",
  "page3ScreenFormIncomeProofClaimContentPre": "I,",
  "page3ScreenFormIncomeProofClaimFullNameLabel": "Applicant full name",
  "page3ScreenFormIncomeProofClaimIdCardErrorMessage": "Please enter the valid ID number",
  "page3ScreenFormIncomeProofClaimIdCardLabel": "HKID number",
  "page3ScreenFormIncomeProofClaimLabel": "Income declaration",
  "page3ScreenFormIncomeProofExceptionReasonErrorMessage": "Please enter the reason",
  "page3ScreenFormIncomeProofExceptionReasonIndices0Label": "No income",
  "page3ScreenFormIncomeProofExceptionReasonIndices1Label": "Salary payment in cash",
  "page3ScreenFormIncomeProofExceptionReasonIndices2Label": "Salary payment in cheque",
  "page3ScreenFormIncomeProofExceptionReasonIndices3Label": "Self-employed",
  "page3ScreenFormIncomeProofExceptionReasonIndices4Label": "Payment record is lost",
  "page3ScreenFormIncomeProofExceptionReasonIndices5Label": "Others (Please state reasons)",
  "page3ScreenFormIncomeProofExceptionReasonIndices6Label": "Monthly Statement is lost",
  "page3ScreenFormIncomeProofExceptionReasonIndicesLabel": "If proof cannot be provided for any of the months above, please choose / state reasons (can choose more than one):",
  "page3ScreenFormIncomeProofImageLabel": "Income proof images",
  "page3ScreenFormIncomeProofImageTooltip": "If proof cannot be provided for any of the months above, please choose the reasons below in order to skip this part",
  "page3ScreenFormIncomeProofPeriodAfter1Label": "Income after COVID-19, which is household income 2 months before application (The first month)",
  "page3ScreenFormIncomeProofPeriodAfter2Label": "Income after COVID-19, which is household income 2 months before application (The second month)",
  "page3ScreenFormIncomeProofPeriodAfter3Label": "Income after COVID-19, which is household income 1 month before application (The third month)",
  "page3ScreenFormIncomeProofPeriodAfterRenewalLabel": "%{month} %{year} Family total income amount / income proof document",
  "page3ScreenFormIncomeProofPeriodBeforeLabel": "Income before COVID-19",
  "page3ScreenFormIncomeProofYearAndMonthErrorMessage": "Please select the period",
  "page3ScreenFormIncomeProofYearAndMonthLabel": "Time period",
  "page3ScreenFormMemberIdCardCheckedLabel": "File verified",
  "page3ScreenFormMemberIdCardImage": "Identity document",
  "page3ScreenFormMemberNameChinese": "Member Chinese Full Name",
  "page3ScreenFormMemberNameChineseDescrpition": "If there is a Chinese name on the identity document, please fill this in",
  "page3ScreenFormMemberNameChinesePlaceholder": "Chinese Full Name",
  "page3ScreenFormMemberNameEnglish": "Member English Name",
  "page3ScreenFormMemberTitle": "%{index}-th member",
  "page3ScreenFormMembersCountLabel": "No. of family member",
  "page3ScreenFormOctopusCardErrorMessage": "Please enter the valid Octopus Card number",
  "page3ScreenFormOctopusCardErrorMessageDuplicate": "Octopus no. is the same as above",
  "page3ScreenFormOctopusCardLabel": "Octopus Card no.",
  "page3ScreenFormOctopusCardNoneCheckedLabel": "Do not wish to register",
  "page3ScreenFormOctopusCardNoneCheckedText": "Not to provide",
  "page3ScreenFormOctopusCardTitle": "%{index}-th Octopus Card",
  "page3ScreenFormPersonalInfoClaimButtonLabel": "Click to open Collection Privacy Statement",
  "page3ScreenFormPersonalInfoClaimCheckboxButtonErrorMessage": "Please open and read through the agreement before checking this box",
  "page3ScreenFormPersonalInfoClaimCheckboxText": "I have read and agree to Collection Privacy Statement",
  "page3ScreenFormPersonalInfoClaimErrorMessage": "Please agree to personal info collection statement",
  "page3ScreenFormPersonalInfoClaimLabel": "Personal info collection statement",
  "page3ScreenFormPersonalInfoClaimTextLinkLabel": "Personal info collection statement",
  "page3ScreenFormPersonalInfoClaimTextPre": "I have read and agree to",
  "page3ScreenFormPreferredKioskAreaLabel": "AFD region",
  "page3ScreenFormPreferredKioskDisabledLabel": "(currently full)",
  "page3ScreenFormPreferredKioskLabel": "Preferred AFD",
  "page3ScreenFormSectionAddressProofDescription": "Please upload images of address proof documents (accept water bill / electricity bill / rental agreement / bank letters, jpeg/png/pdf format)",
  "page3ScreenFormSectionAddressProofTitle": "Address Proof Document",
  "page3ScreenFormSectionFoodSafetyAndPersonalInfoClaimTitle": "Food Safety and Personal Information Collection Statement",
  "page3ScreenFormSectionIncomeProofDescription": "Please complete and upload proof of household income before and after the COVID-19.\nThe amount of income before/after the impact of the epidemic must be filled in. If you are unemployed, you can enter $0.\nFamily as a unit, if there is more than one person working in the family in the month, please fill in the total income of all family members in the month and provide the relevant income proof.\nThe income proof accepts bank passbooks, monthly statements, pay slips, payroll records, and must clearly show the income amount.",
  "page3ScreenFormSectionIncomeProofDescriptionRenewal": "Please complete and upload proof of household income for the 3 recent months.\nThe amount of income must be filled in. Enter $0 if you are unemployed.\nFamily as a unit, if there is more than one person working in the family in the month, please fill in the total income of all family members in the month and provide the relevant income proof.\nThe income proof accepts bank passbooks, monthly statements, pay slips, payroll records, and must clearly show the income amount.\n",
  "page3ScreenFormSectionIncomeProofTitle": "Family Salary Document",
  "page3ScreenFormSectionMemberDescription": "Please fill in the English full name and upload HK identity document for each member. (jpeg/png/pdf format)",
  "page3ScreenFormSectionMemberTitle": "Family Member",
  "page3ScreenFormSectionOctopusCardsDescription": "Members can collect meals with a QR code provided, or with a registered Octopus Card. You may register up to 2 Octopus Cards.",
  "page3ScreenFormSectionOctopusCardsTitle": "Register Octopus Cards",
  "page3ScreenFormSectionPreferredKioskDescription": "Members may collect meals from any AFD. Please select the location of your first priority",
  "page3ScreenFormSectionPreferredKioskTitle": "Choose the preferred AFD",
  "page3ScreenHeaderInfo": "Please make sure the images are clear for view, or else would be considered invalid.",
  "page3ScreenIncompleteFormMessage": "Some of your information is incomplete. Please go back and check again",
  "page3ScreenInvalidIncomeAmountFormMessage": "The entered family income does not show affect by the epidemic or is not decrease. It does not meet the qualifications of application. (Family income needs to be affected by the epidemic and decreases, and the average income upper limit of the last three months: $%{amount})",
  "page3ScreenInvalidIncomeAmountFormTitle": "Submission Failed. Please refill income information",
  "page3ScreenMessageSaved": "Successfully saved",
  "page3ScreenMessageSaving": "Auto-saving...",
  "page3ScreenOctopusHelpModalText": "If you use a physical Octopus card, you can find the number at the front or back of the card. Please enter the complete number of 8 or 9 digits, and you must also fill in the number in brackets.",
  "page3ScreenOctopusHelpModalTitle": "How to find the Octopus Card number",

  "page4ScreenResultExpiredExtraButton": "Visit the programme webpage",
  "page4ScreenResultSubTitleAccepted": "Your application has been approved, the Food Angel team will inform the AFD service start date. Please wait.",
  "page4ScreenResultSubTitleApproved": "Your application is being processed, please wait.",
  "page4ScreenResultSubTitleExpired": "As it is passed documentation submission deadline, the application is rejected. Please visit the programme webpage if you wish to reapply. Thank you.",
  "page4ScreenResultSubTitleNotApproved": "As the case does not fit the programme criteria, your application has been rejected. Please visit the programme webpage to reapply for other food assistance services. Thank you.",
  "page4ScreenResultSubTitleRejected": "Food Angel will follow the instruction and reject the application from system within 5 working days. After rejection, you will receive an automatic SMS from Jockey Club. Thank you.",
  "page4ScreenResultSubTitleSelectedEfoodBank": "As the personal data input do not meet the criteria of short term meal assistance service, Food Angel team will withdraw your AFD service application from system within 5 working days, and you will receive an automatic SMS from Hong Kong Jockey Club. You will need to reapply the E-food bank service on the official programme website, and your case will be handled by the assigned NGO operator. Thank you.",
  "page4ScreenResultSubTitleSelectedHotMeal": "Food Angel staff will refer your case to Food For Good to continue the application for hot meals accordingly within 5 working days. Due to large number of applications, referral will take time, please be patient. Thank you.",
  "page4ScreenResultSubTitleSubmitted": "Due to large number of applications, the approval process will take some time, please be patient. If application is successful, you will receive a SMS with the AFD service start date and other member information. Thank you.",
  "page4ScreenResultTitleAccepted": "Application is approved",
  "page4ScreenResultTitleApproved": "Application is being processed",
  "page4ScreenResultTitleExpired": "Document submission deadline is passed",
  "page4ScreenResultTitleNotApproved": "Application is rejected",
  "page4ScreenResultTitleRejected": "Confirmed to reject the application of AFD service",
  "page4ScreenResultTitleSelectedEfoodBank": "Confirmed to apply E-food bank service",
  "page4ScreenResultTitleSelectedHotMeal": "Confirmed to transfer to hot meal service",
  "page4ScreenResultTitleSubmitted": "Document submission is completed"
}