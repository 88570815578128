export default {
  "appFooterMessageOr": "或",
  "appFooterMessagePost": "。谢谢。",
  "appFooterMessagePre": "如有查询，可以文字讯息联络「自在食」热线：",

  "appHeaderTitleMain": "「赛马会逆境同行食物援助计划」",

  "appHeadertitleSub": "惜食堂「自在食」自助速冻餐提取机服务",

  "componentOctopusInputNoCheckDigitCheckedLabel": "如八达通卡号没有括号的数字, 请勾选此栏",

  "componentUploadFileButtonLabel": "选择图片",
  "componentUploadFileOpenFileButtonLabel": "在新视窗打开文件",
  "componentUploadFilePreviewTitle": "预览文件",
  "componentUploadFileRemoveConfirmMessage": "请定要删除这个图片吗?",

  "errorMessageRequiredInputAny": "请输入%{name}",
  "errorMessageRequiredSelectAny": "请选择%{name}",
  "errorMessageRequiredUploadAny": "请上传%{name}",
  "errorMessageUnknownError": "未知错误, 请稍后重试",

  "page1ScreenButtonSubmitLabel": "确定",
  "page1ScreenErrorMessageInvalidToken": "验证码不正确, 请检查清楚你的短讯息",
  "page1ScreenErrorMessageInvalidUser": "抱歉, 找不到此申请记录。如有问题请联络我们",
  "page1ScreenFormAlertContentPost": "。请勿随时分享此连结给其他人！",
  "page1ScreenFormAlertContentPre": "此申请表格只属于申请人",
  "page1ScreenFormAlertTitle": "请注意",
  "page1ScreenFormTokenLabel": "四位数字验证码",
  "page1ScreenFormTokenLengthErrorMessage": "请输入四位数字验证码",
  "page1ScreenHeaderSub": "「自在食」申请程序",
  "page1ScreenHeaderSubRenewal": "「自在食」延续服务申请程序",
  "page1ScreenHeaderSubRight": "申请人: %{name}",
  "page1ScreenSuccessMessage": "验证码正确",
  "page1ScreenTitle": "请输入短讯内的验证码, 才可继续申请程序",

  "page2ScreenButtonSubmitLabel": "下一步",
  "page2ScreenFormStatusErrorMessage": "请标明申请意向",
  "page2ScreenFormStatusLabel": "请标明申请意向：",
  "page2ScreenFormStatusOptionConfirm": "确定",
  "page2ScreenFormStatusOptionReject": "拒绝",
  "page2ScreenFormStatusOptionSelectEFoodBank": "E+ 食",
  "page2ScreenFormStatusOptionSelectHotMeal": "趁热食",
  "page2ScreenIntroduction1": "惜食堂「自在食」自助速冻餐提取机计划为「赛马会逆境同行食物援助计划」中的长期服务，成功申请成为会员，可用二维码或已登记的八达通卡，于不同区域的自助机，领取长达一年的速冻餐，自行在家翻热享用。",
  "page2ScreenIntroduction2": "自助机位置请参阅惜食堂网页：",
  "page2ScreenIntroduction3": "请于 *5个工作天内* 完成以下登记程序 ，否则系统会自动取消你的服务申请 。服务申请一经取消，你需要重新网上登记及轮候。",
  "page2ScreenIntroduction4": "由于轮候自在食服务人数众多，如申请成功批核，有机会需要等候3至6个月才能正式开始服务，而实际等候时间按各区当时情况而定。",
  "page2ScreenIntroductionOption1": "我确定申请「自在食」服务并会五天内上载所需文件以完成计划申请，亦明白有机会需要轮候3至6个月才能启用服务。",
  "page2ScreenIntroductionOption2": "我拒绝申请「自在食」服务。我了解申请流程将在此结束，并会收到由赛马会系统送出的服务取消短讯。 ",
  "page2ScreenIntroductionOption3": "我想转为申请齐惜福的「趁热食」服务，了解惜食堂职员会跟进转介。 ",
  "page2ScreenIntroductionOption4": "我想申请短期服务「E+ 食」，并了解申请表内已输入的资料不符合短期服务条件，惜食堂职员将终止我的「自在食」服务申请，我会自行重新网上申请「E+ 食」服务。 ",
  "page2ScreenIntroductionRenewal1": "请于五天内完成文件上载, 提交申请, 否则会按原定日期(开始服务起满52周)结束自在食服务",
  "page2ScreenIntroductionRenewal2": "请留意, 可获续期人数有限, 惜食堂社工团队会按照家庭入息状况进行审核, 审核需时,请耐心等候, 稍后会以短讯通知合资格会员有关安排",
  "page2ScreenIntroductionRenewalOption1": "我知悉服务结束日期, 现在没有食物援助需要, 毋须延续服务",
  "page2ScreenIntroductionRenewalOption2": "我仍然有食物援助需要,希望申请自在食计划延期, 并会上载申请文件",
  "page2ScreenStatusModalConfirmButtonCancelLabel": "返回",
  "page2ScreenStatusModalConfirmButtonConfirmLabel": "确定要继续",
  "page2ScreenStatusModalConfirmContentReject": "申请流程将在此结束。你将收到由赛马会系统送出的服务取消短讯。",
  "page2ScreenStatusModalConfirmContentSelectEfoodBank": "由于申请表内已输入的资料不符合短期服务条件，职员会为你终止「自在食」申请，你需自行重新网上申请「E+ 食」服务。",
  "page2ScreenStatusModalConfirmContentSelectHotMeal": "我们同事会跟进转介。",
  "page2ScreenStatusModalConfirmTitleReject": "你选择了拒绝申请「自在食」",
  "page2ScreenStatusModalConfirmTitleSelectEfoodBank": "你选择了申请短期服务「E+ 食」",
  "page2ScreenStatusModalConfirmTitleSelectHotMeal": "你选择了申请齐惜福的「趁热食」服务",
  "page2ScreenStatusRenewalModalConfirmContentReject": "确定知悉服务结束日期, 毋须申请延续服务",
  "page2ScreenStatusRenewalModalConfirmTitleReject": "你选择了不需要申请延续服务",
  "page2ScreenSubmitModalButtonCancelLabel": "返回",
  "page2ScreenSubmitModalButtonConfirmLabel": "确定提交",
  "page2ScreenSubmitModalContent": "你确定提交申请资料? 提交后不能再修改",
  "page2ScreenSubmitModalTitle": "提交申请资料",
  "page2ScreenTitle": "服务简介及文件上载",

  "page3ScreenAutosavingMessageSaveButtonLabel": "保存",
  "page3ScreenAutosavingMessageSaved": "资料已自动保存",
  "page3ScreenAutosavingMessageSaving": "自动保存中...",
  "page3ScreenAutosavingMessageUnsaved": "目前有资料未保存",
  "page3ScreenButtonSubmitLabel": "提交",
  "page3ScreenErrorMessageInvalidToken": "操作超时, 请重新登入",
  "page3ScreenFoodSafetyCheckboxButtonErrorMessage": "请先打开声明并阅读后, 才能勾选此项",
  "page3ScreenFoodSafetyCheckboxButtonLabel": "按此查看食安声明",
  "page3ScreenFoodSafetyCheckboxText": "本人已阅读并同意食安声明",
  "page3ScreenFormAddressProofImage": "住址证明",
  "page3ScreenFormFoodSafetyErrorMessage": "请同意食安声明",
  "page3ScreenFormFoodSafetyLabel": "食安声明",
  "page3ScreenFormIncomeProofAmountErrorMessageInvalid": "请输入正确金额",
  "page3ScreenFormIncomeProofAmountLabel": "家庭入息金额",
  "page3ScreenFormIncomeProofClaimContentPost": "声明以上所填报的事项，全部属实，正确无讹。本人明白及同意如作出虚假陈述或提供虚假资料以骗取食物援助，将导致本人及家庭成员丧失接受服务资格，而有关机构亦保留一切追究权利。",
  "page3ScreenFormIncomeProofClaimContentPre": "本人",
  "page3ScreenFormIncomeProofClaimFullNameLabel": "申请人全名",
  "page3ScreenFormIncomeProofClaimIdCardErrorMessage": "请输入有效身份证号码",
  "page3ScreenFormIncomeProofClaimIdCardLabel": "身份证号码",
  "page3ScreenFormIncomeProofClaimLabel": "收入申报声明",
  "page3ScreenFormIncomeProofExceptionReasonErrorMessage": "请输入原因",
  "page3ScreenFormIncomeProofExceptionReasonIndices0Label": "没有收入",
  "page3ScreenFormIncomeProofExceptionReasonIndices1Label": "现金出粮",
  "page3ScreenFormIncomeProofExceptionReasonIndices2Label": "支票出粮",
  "page3ScreenFormIncomeProofExceptionReasonIndices3Label": "自雇人士",
  "page3ScreenFormIncomeProofExceptionReasonIndices4Label": "遗失粮单",
  "page3ScreenFormIncomeProofExceptionReasonIndices5Label": "其他（请填写原因）",
  "page3ScreenFormIncomeProofExceptionReasonIndices6Label": "遗失月结单",
  "page3ScreenFormIncomeProofExceptionReasonIndicesLabel": "若以上任何月份未能提供文件，请列明原因 （可选多个一项）",
  "page3ScreenFormIncomeProofImageLabel": "入息证明图片",
  "page3ScreenFormIncomeProofImageTooltip": "如未能提供文件, 请在下面选择原因便能跳过此步骤",
  "page3ScreenFormIncomeProofPeriodAfter1Label": "受疫情影响后 及 申请服务时最近3个月的家庭入息 (第一个月)",
  "page3ScreenFormIncomeProofPeriodAfter2Label": "受疫情影响后 及 申请服务时最近2个月的家庭入息 (第二个月)",
  "page3ScreenFormIncomeProofPeriodAfter3Label": "受疫情影响后 及 申请服务时最近1个月的家庭入息 (第三个月)",
  "page3ScreenFormIncomeProofPeriodAfterRenewalLabel": "%{year}年%{month}月 家庭入息金额/入息证明文件",
  "page3ScreenFormIncomeProofPeriodBeforeLabel": "受疫情影响前的家庭入息",
  "page3ScreenFormIncomeProofYearAndMonthErrorMessage": "请选择时段",
  "page3ScreenFormIncomeProofYearAndMonthLabel": "选择时段",
  "page3ScreenFormMemberIdCardCheckedLabel": "文件已认证",
  "page3ScreenFormMemberIdCardImage": "身份证明文件",
  "page3ScreenFormMemberNameChinese": "家庭成员中文全名",
  "page3ScreenFormMemberNameChineseDescrpition": "如身份证明文件上有中文姓名, 请必须填写此栏",
  "page3ScreenFormMemberNameChinesePlaceholder": "中文全名",
  "page3ScreenFormMemberNameEnglish": "家庭成员英文姓名",
  "page3ScreenFormMemberTitle": "第 %{index} 位成员",
  "page3ScreenFormMembersCountLabel": "申请家庭人数",
  "page3ScreenFormOctopusCardErrorMessage": "请输入有效八达通编号",
  "page3ScreenFormOctopusCardErrorMessageDuplicate": "八达通号重覆了",
  "page3ScreenFormOctopusCardLabel": "八达通卡编号",
  "page3ScreenFormOctopusCardNoneCheckedLabel": "暂不提供八达通号",
  "page3ScreenFormOctopusCardNoneCheckedText": "暂不提供",
  "page3ScreenFormOctopusCardTitle": "第 %{index} 个八达通",
  "page3ScreenFormPersonalInfoClaimButtonLabel": "按此查看使用者收集私隐声明",
  "page3ScreenFormPersonalInfoClaimCheckboxButtonErrorMessage": "请先打开声明并阅读后, 才能勾选此项",
  "page3ScreenFormPersonalInfoClaimCheckboxText": "本人已阅读并同意使用者收集私隐声明",
  "page3ScreenFormPersonalInfoClaimErrorMessage": "请同意个人资料收集声明",
  "page3ScreenFormPersonalInfoClaimLabel": "个人资料收集声明",
  "page3ScreenFormPersonalInfoClaimTextLinkLabel": "个人资料收集声明",
  "page3ScreenFormPersonalInfoClaimTextPre": "本人已阅读并同意",
  "page3ScreenFormPreferredKioskAreaLabel": "自助机地区",
  "page3ScreenFormPreferredKioskDisabledLabel": "(暂时额满)",
  "page3ScreenFormPreferredKioskLabel": "首选自助机",
  "page3ScreenFormSectionAddressProofDescription": "请上载住址证明图片 （接受水费单/电费单/租单/银行信件, jpeg/png/pdf 格式）",
  "page3ScreenFormSectionAddressProofTitle": "住址证明",
  "page3ScreenFormSectionFoodSafetyAndPersonalInfoClaimTitle": "食安及个人资料收集声明",
  "page3ScreenFormSectionIncomeProofDescription": "请填写并上载疫情前后的家庭收入证明。\n必须填写受疫情影响前/后的入息金额，若失业可输入 $0。\n以家庭为单位，若家庭中于该月份有多过1人有工作，请于该月份填写所有家庭成员的总入息，及提供相关入息证明。\n入息证明接受银行存折、月结单、粮单、出粮纪录，并需清楚显示入息金额",
  "page3ScreenFormSectionIncomeProofDescriptionRenewal": "请填写并上载最近三个月收入证明。必须填写入息金额，若失业可输入 $0。 \n以家庭为单位，若家庭中于该月份有多过1人有工作，请于该月份填写所有家庭成员的总入息，及提供相关入息证明。 \n入息证明接受银行存折、月结单、粮单、出粮纪录，并需清楚显示入息金额\n",
  "page3ScreenFormSectionIncomeProofTitle": "家庭收入证明",
  "page3ScreenFormSectionMemberDescription": "请填写各家庭成员中文名称及上载所有家庭成员香港身份证明文件 (jpeg/png/pdf 格式)",
  "page3ScreenFormSectionMemberTitle": "家庭成员",
  "page3ScreenFormSectionOctopusCardsDescription": "会员可用二维码或已登记的八达通, 于自助机取餐. 请输入1至2张八达通卡号码, 以作登记",
  "page3ScreenFormSectionOctopusCardsTitle": "登记八达通会员卡",
  "page3ScreenFormSectionPreferredKioskDescription": "会员可以选择从任何区域的自助机取餐, 请选择首选机以制作会员卡",
  "page3ScreenFormSectionPreferredKioskTitle": "选择首选自助机",
  "page3ScreenHeaderInfo": "请确保所有上载照片画面清晰, 否则可视为失效",
  "page3ScreenIncompleteFormMessage": "你有部份资料未齐全，请返回检查一次",
  "page3ScreenInvalidIncomeAmountFormMessage": "未能显示家庭收入受疫情影响而减少，不符合申请资格。 （家庭收入需受疫情影响而减少及最近三个月的平均入息上限: $%{amount})",
  "page3ScreenInvalidIncomeAmountFormTitle": "提交失败，请重新填写入息资料",
  "page3ScreenMessageSaved": "保存成功",
  "page3ScreenMessageSaving": "自动保存中...",
  "page3ScreenOctopusHelpModalText": "如使用实体八达通卡，可查看卡的正面或反面，请输入8位或9位之完整编号，当附有括号号码时也须一并填写。",
  "page3ScreenOctopusHelpModalTitle": "如何找到八达通卡号",

  "page4ScreenResultExpiredExtraButton": "前往计划网页",
  "page4ScreenResultSubTitleAccepted": "你的申请已成功批核，惜食堂团队将通知「自在食」服务开始日期，请耐心等候。",
  "page4ScreenResultSubTitleApproved": "您的申请仍在审批阶段，请耐心等候。",
  "page4ScreenResultSubTitleExpired": "由于已过上载文件期限，「自在食」服务申请已被终止。谢谢。若重新申请，请于计划网页重新填写资料。 ",
  "page4ScreenResultSubTitleNotApproved": "因个案不符合计划条件，你的申请已被拒绝。若选择其他食物援助服务，请于计划网页重新填写资料。",
  "page4ScreenResultSubTitleRejected": "惜食堂团队会按照你的申请意向，于五个工作天内于系统取消您的申请。申请取消后，您将会收到由赛马会系统送出的服务取消短讯。谢谢。",
  "page4ScreenResultSubTitleSelectedEfoodBank": "由于申请表内已输入的资料不符合短期服务条件，惜食堂团队会于五个工作天内于系统终止您的「自在食」申请，您将会收到由赛马会系统送出的服务取消短讯。您需自行重新网上申请「E+食」服务，而个案会交由系统安排的慈善团体跟进。谢谢",
  "page4ScreenResultSubTitleSelectedHotMeal": "惜食堂团队会按照你的申请意向，于五个工作天内将你的申请转介予齐惜福跟进。由于申请人数众多，申请转介后亦请耐心等候。谢谢。",
  "page4ScreenResultSubTitleSubmitted": "惜食堂团队会按申请人递交完整文件的次序处理审批，由于申请人数众多，审批需时，请耐心等候。批核成功后，将会获发短讯，通知服务开始日期及其他会员资料。谢谢。",
  "page4ScreenResultTitleAccepted": "申请已被批核",
  "page4ScreenResultTitleApproved": "申请仍在审批",
  "page4ScreenResultTitleExpired": "上载文件已过时",
  "page4ScreenResultTitleNotApproved": "申请已被拒绝",
  "page4ScreenResultTitleRejected": "已确定终止「自在食」计划申请",
  "page4ScreenResultTitleSelectedEfoodBank": "已确定你选择取消申请「自在食」服务，并会自行于网页重新申请短期服务「E+ 食」",
  "page4ScreenResultTitleSelectedHotMeal": "已确定申请「趁热食」服务",
  "page4ScreenResultTitleSubmitted": "已完成上载所需文件及参阅须知"
}